import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import About from "../containers/about";

const AboutlPage = () => (
  <Layout>
    <Seo title="Page two" />
    <About />
  </Layout>
);

export default AboutlPage;

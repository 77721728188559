import * as React from "react";
const About = () => (
  <div className="flex flex-wrap overflow-hidden">
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-1">
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654839864/training%20center/about/training_center_mmn25v.webp"
        alt="hua hin muay thai team"
      />
    </div>

    <div className="bg-geraniumlake w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 p-8 lg:p-16 order-2 ">
      <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold py-12 text-white">
        ELITE TRAINING CENTER
      </h2>
      <p className="text-xl md:text-3xl lg:text-xl py-6 text-white leading-loose md:leading-loose lg:leading-loose uppercase lg:pr-20">
        elite training center is the leading premium combat, fitness and contact
        sports training facility based in thailand. with 2 locations currently
        operational, 1 in bangkok, which sits on a 2000+ sqm facility
        comprimising of the state of the art fully equipped gym, swimming pool,
        steam room, sauna, locker room and shower facility.
      </p>
    </div>
    <div className="bg-white pt-16 md:pt-20 md:pb-16 p-8 lg:p-20 w-full overflow-hidden  lg:w-1/2 xl:w-1/2 order-4 lg:order-3">
      <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold pb-12  text-geraniumlake">
        Community
      </h2>
      <p className="text-xl md:text-3xl lg:text-xl py-2 text-black leading-loose md:leading-loose lg:leading-loose uppercase">
        A meeting point and a place for growth personally and as a team. Come
        down and meet us and see for yourself what the Elite community is about.
      </p>{" "}
    </div>
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-3 lg:order-4">
      {" "}
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654743536/training%20center/home/exercises-gym-training-bangkok_sbgyqn.webp"
        alt="exercises gym training bangkok muay thai team"
      />
    </div>
  </div>
);

export default About;
